exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-98-tsx": () => import("./../../../src/pages/98.tsx" /* webpackChunkName: "component---src-pages-98-tsx" */),
  "component---src-pages-barcodes-tsx": () => import("./../../../src/pages/barcodes.tsx" /* webpackChunkName: "component---src-pages-barcodes-tsx" */),
  "component---src-pages-card-maker-tsx": () => import("./../../../src/pages/cardMaker.tsx" /* webpackChunkName: "component---src-pages-card-maker-tsx" */),
  "component---src-pages-ide-tsx": () => import("./../../../src/pages/ide.tsx" /* webpackChunkName: "component---src-pages-ide-tsx" */),
  "component---src-pages-image-edit-tsx": () => import("./../../../src/pages/imageEdit.tsx" /* webpackChunkName: "component---src-pages-image-edit-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kb-bg-tsx": () => import("./../../../src/pages/kb-bg.tsx" /* webpackChunkName: "component---src-pages-kb-bg-tsx" */),
  "component---src-pages-layout-genarator-tsx": () => import("./../../../src/pages/layout-genarator.tsx" /* webpackChunkName: "component---src-pages-layout-genarator-tsx" */),
  "component---src-pages-menu-1-tsx": () => import("./../../../src/pages/menu1.tsx" /* webpackChunkName: "component---src-pages-menu-1-tsx" */),
  "component---src-pages-menu-2-tsx": () => import("./../../../src/pages/menu2.tsx" /* webpackChunkName: "component---src-pages-menu-2-tsx" */),
  "component---src-pages-menu-3-tsx": () => import("./../../../src/pages/menu3.tsx" /* webpackChunkName: "component---src-pages-menu-3-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-pos-tsx": () => import("./../../../src/pages/pos.tsx" /* webpackChunkName: "component---src-pages-pos-tsx" */),
  "component---src-pages-print-tsx": () => import("./../../../src/pages/print.tsx" /* webpackChunkName: "component---src-pages-print-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-terminal-tsx": () => import("./../../../src/pages/terminal.tsx" /* webpackChunkName: "component---src-pages-terminal-tsx" */),
  "component---src-pages-tester-tsx": () => import("./../../../src/pages/tester.tsx" /* webpackChunkName: "component---src-pages-tester-tsx" */)
}

